<template>
  <div class="user-tab-overview">
    <!-- overview -->
    <v-card>
      <v-card-title>
        {{ t('Content') }}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="data.content" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf } from '@mdi/js'
import { useUtils } from '@/@core/libs/i18n'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useUtils()

    return {
      t,
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
      },
    }
  },
}
</script>
