<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="user-edit-info pt-10">
        <v-card-title class="justify-center flex-column">
          <a
            v-if="data.thumbUrl"
            target="_blank"
            :href="data.thumbUrl"
          >
            <v-avatar
              :color="data.image ? '' : 'primary'"
              :class="data.image ? '' : 'v-avatar-light-bg primary--text'"
              size="120"
              rounded
              class="mb-4"
            >
              <v-img
                :src="data.thumbUrl"
                :alt="`${data.title} image`"
              ></v-img>
            </v-avatar>
          </a>
          <v-avatar
            v-else
            :color="data.image ? '' : 'primary'"
            :class="data.image ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <span
              class="font-weight-semibold text-5xl"
            >{{ avatarText(data.title) }}</span>
          </v-avatar>

          <span class="mb-2">{{ data.title | textEllipsis(90) }}</span>

          <v-chip
            label
            small
            color="primary"
            class="v-chip-light-bg text-sm font-weight-semibold primary--text text-capitalize"
          >
            {{ data.subtitle | textEllipsis(55) }}
          </v-chip>
        </v-card-title>

        <v-card-subtitle class="align-items-center">
          <v-chip-group
            column
            center-active
            class="v-chip-group--centered"
          >
            <v-chip
              v-for="(category, i) in data.categories"
              :key="category.id"
              small
              link
              :class="{
                'mr-0': (categoriesLength - 1) === i
              }"
              :to="{ name: 'news-category-list', params: { id: category.id }}"
            >
              {{ category.name }}
            </v-chip>
          </v-chip-group>
        </v-card-subtitle>

        <v-card-text>
          <h2 class="d-flex flex justify-space-between align-center text-xl font-weight-semibold mb-2">
            {{ t('Details') }}

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="rounded-0 self-end"
                  :color="data.visibility ? 'primary' : 'error'"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ data.visibility ? icons.mdiEyeCheckOutline : icons.mdiEyeOffOutline }}
                </v-icon>
              </template>
              <span v-if="data.visibility">{{ t('Visible') }}</span>
              <span v-else>{{ t('Hidden') }}</span>
            </v-tooltip>
          </h2>

          <v-divider></v-divider>

          <v-list
            one-line
            :two-line="false"
          >
            <v-list-item
              class="px-0 mb-n2"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium me-2">{{ t('ID') }}:</span>
                  <span class="text--secondary">{{ data.id }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0 mb-n2">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium me-2">{{ t('Title') }}:</span>
                  <span class="text--secondary">{{ data.title }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0 mb-n2">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium me-2">{{ t('Subtitle') }}:</span>
                  <span class="text--secondary">{{ data.subtitle }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0 mb-n2">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium me-2">{{ t('Slug') }}:</span>
                  <span class="text--secondary">{{ data.slug }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0 mb-n2">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium me-2">{{ t('Date') }}:</span>
                  <span class="text--secondary">{{ data.date }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0 mb-n2">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium text-no-wrap me-2">{{ t('URL') }}:</span>
                  <a
                    v-if="data.url"
                    :href="data.url"
                    target="_blank"
                  >{{ data.url }}</a>
                  <span v-else>-</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="px-0 mb-n2"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium text-no-wrap me-2">{{ t('Image URL') }}:</span>
                  <a
                    v-if="data.image"
                    :href="data.thumbUrl"
                    target="_blank"
                  >{{ data.thumbUrl }}</a>
                  <span v-else>-</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0 mb-n2">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium text-no-wrap me-2">{{ t('Vimeo Video') }}:</span>
                  <a
                    v-if="data.vimeoId"
                    :href="generateVimeoUrl(data.vimeoId)"
                    target="_blank"
                  >{{ generateVimeoUrl(data.vimeoId) }}</a>
                  <span v-else>-</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0 mb-n2">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium text-no-wrap me-2">{{ t('Vimeo Video Thumbnail') }}:</span>
                  <a
                    v-if="data.vimeoThumbUrl"
                    :href="data.vimeoThumbUrl"
                    target="_blank"
                  >{{ data.vimeoThumbUrl }}</a>
                  <span v-else>-</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0 mb-n2">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium text-no-wrap me-2">{{ t('Preview URL') }}:</span>
                  <a
                    :href="data.links.preview"
                    target="_blank"
                  >{{ data.links.preview }}</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions
          v-if="$can('update', 'News')"
          class="justify-center"
        >
          <v-btn
            color="primary"
            :to="{ name: 'news-edit', params: { id: data.id } }"
          >
            {{ t('Edit') }}
          </v-btn>
          <v-btn
            exact
            outlined
            color="secondary"
            :to="{ name: 'news-list' }"
          >
            {{ t('Discard') }}
          </v-btn>
          <v-btn
            exact
            outlined
            :color="data.visibility ? 'info' : 'error'"
            target="_blank"
            :href="data.links.preview"
          >
            {{ t('Preview') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiEyeCheckOutline, mdiEyeOffOutline, mdiTagMultipleOutline } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { useUtils } from '@core/libs/i18n'
import { computed } from '@vue/composition-api'

// import NewsBioEdit from './NewsBioEdit.vue'

export default {
  components: {
    // NewsBioEdit,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    handleUpdate: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { t } = useUtils()

    // const isBioDialogOpen = ref(false)
    const generateVimeoUrl = videoId => `https://player.vimeo.com/video/${videoId}`

    const categoriesLength = computed(() => props.data?.categories?.length ?? 0)

    return {
      avatarText,
      kFormatter,

      categoriesLength,

      // isBioDialogOpen,
      icons: {
        mdiTagMultipleOutline,
        mdiEyeCheckOutline,
        mdiEyeOffOutline,
      },

      t,

      generateVimeoUrl,
    }
  },
}
</script>

<style lang="scss">
.v-chip-group {
  &--centered {
    .v-slide-group__wrapper {
      .v-slide-group__content {
        justify-content: center;
      }
    }
  }
}
</style>
