import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-tab-overview"},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.t('Media'))+" ")]),_c(VDivider),_c(VCardText,[(_vm.data.images.length)?_c(VRow,_vm._l((_vm.data.images),function(mediaImage){return _c(VCol,{key:mediaImage,attrs:{"cols":"12","sm":"4"}},[_c(VSheet,{attrs:{"elevation":"4"}},[_c('a',{attrs:{"href":_vm.cb.getMediaPath(mediaImage),"target":"_blank"}},[_c('figure',[_c(VImg,{attrs:{"contain":"","src":_vm.cb.getMediaPath(mediaImage)}}),_c('figcaption',{staticClass:"text-caption text-center px-3 py-2"},[_vm._v(" "+_vm._s(mediaImage)+" ")])],1)])])],1)}),1):_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column align-center justify-center "},[_c(VIcon,{staticClass:"mb-3",attrs:{"size":"3rem"}},[_vm._v(" "+_vm._s(_vm.icons.mdiImageOffOutline)+" ")]),_c('div',{staticClass:"text-h6 text-muted"},[_vm._v(" "+_vm._s(_vm.t('No media found!'))+" ")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }