<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <news-bio-panel
          v-if="news"
          :data="news"
          :handle-update="updateData"
        ></news-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="newsTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :append="tab.append"
            :to="tab.to"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="newsTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            v-if="news"
            value="overview"
          >
            <news-tab-overview :data="news" />
          </v-tab-item>

          <v-tab-item
            v-if="news"
            value="media"
          >
            <news-tab-media :data="news" />
          </v-tab-item>

          <v-tab-item
            v-if="news"
            value="call-to-actions"
          >
            <news-tab-call-to-actions :data="news" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, ref, provide } from '@vue/composition-api'
import { mdiAccountOutline, mdiCodeJson, mdiImageMultipleOutline } from '@mdi/js'
import { useRouter } from '@core/utils'

import { useUtils } from '@core/libs/i18n'
import NewsTabCallToActions from './news-tab-call-to-actions/NewsTabCallToActions.vue'
import NewsTabMedia from './news-tab-media/NewsTabMedia.vue'
import NewsBioPanel from './news-bio-panel/NewsBioPanel.vue'
import NewsTabOverview from './news-tab-overview/NewsTabOverview.vue'
import useNewsView from './useNewsView'

export default {
  components: {
    NewsBioPanel,
    NewsTabOverview,
    NewsTabCallToActions,
    NewsTabMedia,
  },
  setup() {
    const newsTab = ref(null)
    const { route } = useRouter()
    const { t } = useUtils()

    const {
      news,
      loading,
      updating,

      errorMessages,

      init,
      destroy,

      updateData,
    } = useNewsView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    const tabs = [
      {
        icon: mdiAccountOutline,
        title: t('Overview'),
        append: true,
        to: '#overview',
      }, {
        icon: mdiImageMultipleOutline,
        title: t('Media'),
        append: true,
        to: '#media',
      }, {
        icon: mdiCodeJson,
        title: t('Call To Actions'),
        append: true,
        to: '#call-to-actions',
      },
    ]

    provide('newsUpdating', updating)
    provide('newsErrorMessages', errorMessages)

    return {
      tabs,
      newsTab,

      news,
      loading,
      updating,
      updateData,

      errorMessages,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
