<template>
  <div class="user-tab-overview">
    <!-- call to actions -->
    <v-card>
      <v-card-title>
        {{ t('Call To Actions') }}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-start">
                  {{ t('Title') }}
                </th>
                <th class="text-start">
                  {{ t('Link') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="data.callToActions.length">
                <tr
                  v-for="item in data.callToActions"
                  :key="item"
                >
                  <td class="text-start">
                    {{ item.title }}
                  </td>

                  <td class="text-start">
                    <a
                      :href="item.link"
                      target="_blank"
                    >
                      {{ item.link }}
                    </a>
                  </td>
                </tr>
              </template>

              <template v-else>
                <tr>
                  <td
                    colspan="2"
                    class="text-center"
                  >
                    {{ t('No records found!') }}
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useUtils()

    return {
      t,
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
