<template>
  <div class="user-tab-overview">
    <!-- media -->
    <v-card>
      <v-card-title>
        {{ t('Media') }}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row v-if="data.images.length">
          <v-col
            v-for="mediaImage in data.images"
            :key="mediaImage"
            cols="12"
            sm="4"
          >
            <v-sheet elevation="4">
              <a
                :href="cb.getMediaPath(mediaImage)"
                target="_blank"
              >
                <figure>
                  <v-img
                    contain
                    :src="cb.getMediaPath(mediaImage)"
                  />
                  <figcaption class="text-caption text-center px-3 py-2">
                    {{ mediaImage }}
                  </figcaption>
                </figure>
              </a>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="12">
            <div class="d-flex flex-column align-center justify-center ">
              <v-icon
                size="3rem"
                class="mb-3"
              >
                {{ icons.mdiImageOffOutline }}
              </v-icon>

              <div class="text-h6 text-muted">
                {{ t('No media found!') }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf, mdiImageOffOutline } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { t } = useUtils()

    const getMediaPath = mediaImage => `${props.data.links.mediaDisk}${mediaImage}`

    return {
      t,
      icons: {
        mdiDotsVertical,
        mdiImageOffOutline,
        mdiFilePdf,
      },

      cb: {
        getMediaPath,
      },
    }
  },
}
</script>
